import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "@/app/store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import simpleEncryptDecrypt from "src/pages/PointEventsPage/simpleEncryptDecrypt";
import {
    fetchLogin,
    selectCurrentUser,
    selectCurrentUserStatus,
} from "src/services/login/loginSlice";

export const useGetCurrentUser = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const status = useAppSelector((state: RootState) =>
        selectCurrentUserStatus(state)
    );
    const currentUser = useAppSelector((state: RootState) =>
        selectCurrentUser(state)
    );

    useEffect(() => {
        const up = searchParams.get("up");
        if (up) {
            const decryptedText = simpleEncryptDecrypt(
                searchParams?.get("up") as string,
                "секретныйКлючЛукойл"
            );
            const [authorize, cookies] = decryptedText.split("Infinity");
            const [username, password] = authorize.split("&");
            localStorage.setItem("password", password);
            localStorage.setItem("username", username);
            cookies.split("; ").forEach((cookie) => {
                const [key, value] = cookie.split("=");
                document.cookie = `${key}=${value}; max-age=3600000; path=/`;
            });
            setSearchParams({});
        }
        dispatch(fetchLogin());
    }, []);

    return {
        currentUser,
        status,
    };
};
