import { Input, InputProps } from "antd";
import React, { FC } from "react";
import styles from "./styles.module.css";
import { SearchOutlined } from "@ant-design/icons";
import classNames from "classnames";

const SearchInput: FC<InputProps> = (props) => {

    const {className, ...rest} = props

    return (
        <div className={styles["search-input"]}>
            <SearchOutlined className={styles["search-icon"]} />{" "}
            <Input className={classNames(styles['search-item'], className)} {...rest} />{" "}
        </div>
    );
};

export default SearchInput;
