import { RestInstanse } from "../../../../app/axiosInstance";
import { getAuth } from "src/helpers/getAuth";
import { ICellMapping } from "../../Types/mapPositions";

const BASE_URL = "/supply-point-cell-mapping";

export const getCellMappings = async (params?: {
    targetSupplyPointPositionId?: number;
}) => {
    try {
        const response = await RestInstanse.get<ICellMapping[]>(BASE_URL, {
            ...getAuth(),
            params,
        });

        const result = response.data.reduce<ICellMapping[]>((acc, cur) => {
            if (!(cur.SourceSupplyPointName && cur.TargetSupplyPointName))
                return acc;
            const modified = {
                ...cur,
                ReserveTestAdditionalRatio: cur.ReserveTestAdditionalRatio
                    ? Math.round(cur.ReserveTestAdditionalRatio * 100)
                    : 0,
            };
            return [...acc, modified];
        }, []);
        return result;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const deleteCellMappings = async (elem: ICellMapping) => {
    try {
        await RestInstanse.delete(BASE_URL, {
            ...getAuth(),
            params: { id: elem.Id },
        });
    } catch (e) {
        console.error(e);
    }
};

export const updateCellMappings = async (elem: ICellMapping) => {
    let reserveCof = elem.ReserveTestAdditionalRatio
        ? +elem.ReserveTestAdditionalRatio / 100
        : 0;
    if (reserveCof > 1) reserveCof = 1;
    try {
        await RestInstanse.put<ICellMapping>(
            BASE_URL,
            {
                ...elem,
                isDefault: elem.IsDefault ?? false,
                ReserveTestAdditionalRatio: reserveCof,
            },
            getAuth()
        );
    } catch (e) {
        console.error(e);
    }
};

export const createCellMappings = async (elem: ICellMapping) => {
    try {
        await RestInstanse.post<ICellMapping>(BASE_URL, elem, getAuth());
    } catch (e) {
        console.error(e);
    }
};
