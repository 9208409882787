import { FC } from "react";
import {
    IPeriodModifier,
    IEventPosition,
    IPointEvent,
} from "src/services/pointEvents/pointEventsSlice";

import { TransitionPeriods, SimplePeriods } from "../";
import styles from "./styles.module.css";
import { usePeriods } from "../../hooks";
import { DisabledSpace } from "../../../../components/UI";
import { EventPeriodType, IEventType } from "src/entities/Types/types";
import ReserveTestPeriods from "../ReserveTestPeriods";
import RedButton from "src/shared/components/RedButton";

type Props = {
    currentType: IEventType | null;
    saveOrUpdateEvent: (periods: IPeriodModifier[]) => void;
    beginDate: string;
    currentEvent?: IPointEvent;
    currentPosition: IEventPosition[];
    role: "lineman" | "acceptor";
    mainEventType: EventPeriodType;
};

const PeriodsFabric: FC<Props> = (props) => {
    const {
        beginDate,
        mainEventType,
        currentPosition,
        currentType,
        currentEvent,
        saveOrUpdateEvent,
        role,
    } = props;

    const isTransition = mainEventType === "transition";
    const isReserveTest = mainEventType === "reverse";

    const { periods, setPeriods, addPeriod, cellMappingsList } = usePeriods({
        currentPosition,
        currentEvent,
        mainEventType,
    });

    const updatePeriod = (id: number, period: IPeriodModifier) => {
        setPeriods((prev) =>
            prev.map((el, index) => (index === id ? period : el))
        );
    };

    return (
        <DisabledSpace isActive={!currentPosition.length} zIndex={3}>
            <div className={styles["periods-container"]}>
                {isTransition ? (
                    <TransitionPeriods
                        periods={periods}
                        setPeriods={setPeriods}
                        beginDate={beginDate}
                        cellMappings={cellMappingsList}
                        currentPosition={currentPosition}
                        updatePeriod={updatePeriod}
                    />
                ) : isReserveTest ? (
                    <ReserveTestPeriods
                        periods={periods}
                        setPeriods={setPeriods}
                        beginDate={beginDate}
                        updatePeriod={updatePeriod}
                    />
                ) : (
                    <SimplePeriods
                        periods={periods}
                        setPeriods={setPeriods}
                        beginDate={beginDate}
                        cellMappings={cellMappingsList}
                        currentType={currentType}
                        currentPosition={currentPosition}
                        updatePeriod={updatePeriod}
                    />
                )}
                {role === "lineman" && (
                    <>
                        <div>
                            <RedButton
                                disabled={!currentPosition.length}
                                onClick={addPeriod}
                            >
                                + Добавить период
                            </RedButton>
                        </div>

                        <RedButton onClick={() => saveOrUpdateEvent(periods)}>
                            Сохранить
                        </RedButton>
                    </>
                )}
            </div>
        </DisabledSpace>
    );
};

export default PeriodsFabric;
