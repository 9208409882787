import { RestInstanse } from "src/app/axiosInstance";
import { ReportStateItem, ResponseTable, TableVariants } from "../types";
import { getAuth } from "src/helpers/getAuth";

export interface ExcelParams {
    type: TableVariants | null;
    // month?: number;
    // year: number;
    // period?: string;
}

export const getCurrentTabs = async (): Promise<ReportStateItem[]> => {
    const promise = new Promise<ReportStateItem[]>((resolve) => {
        setTimeout(() => {
            resolve([
                {
                    subtitle:
                        "МЕCЯЦ: 10 2024 - Весь месяц - [01.10.2024 - 31.10.2024]",
                    type: "Electric",
                    filterValue:
                        "&mode=Month&month=10&year=2024&period=allMonth&date=null",
                    id: 1728287431010,
                },
            ]);
        }, 200);
    });
    const result = await promise;
    return result;
};

export const getRealTable = async (
    params?: ExcelParams
): Promise<ResponseTable | null> => {
    if (params?.type === null) return null
    try {
        const response = await RestInstanse.get<ResponseTable | null>(
            "/resource-consumption-table",
            getAuth()
        );
        return response.data;
    } catch (err) {
        return null;
    }
};
