import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    IReportState,
    PeriodConfiguration,
    ReportStateItem,
    TableVariants,
} from "../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getCurrentTabs } from "../api";

type DataLoadingStatus = "pending" | "success" | "rejected";

interface InitialState {
    promises: {
        [key: string]: string;
    };
    filteredString: string;
    currentVariant: TableVariants;
    filterPeriodCurrent: PeriodConfiguration | null;
    savedReports: ReportStateItem[];
    status: DataLoadingStatus;
    activeIndex: number | null;
    fontSize: string;
    pointedRows: number[];
}

const fontSizeFromStorage: string | null = localStorage.getItem("fontSize");

const initialState: InitialState = {
    promises: {},
    filteredString: "",
    currentVariant: "Electric",
    filterPeriodCurrent: null,
    savedReports: [],
    status: "pending",
    activeIndex: null,
    fontSize: fontSizeFromStorage ?? "16",
    pointedRows: [],
};

export const getReports = createAsyncThunk(
    "asyncReportsThunk",
    async (_, thunkApi) => {
        const response = await getCurrentTabs();
        return response;
    }
);

const excelSlice = createSlice({
    name: "excelSlice",
    initialState,
    reducers: {
        addEditing: (state, action: PayloadAction<[string, string]>) => {
            state.promises = {
                ...state.promises,
                [action.payload[0]]: action.payload[1],
            };
        },
        clearEditings: (state, _) => {
            state.promises = {};
        },
        setFilterValue: (state, action: PayloadAction<string>) => {
            state.filteredString = action.payload;
        },
        setVariant: (state, action: PayloadAction<TableVariants>) => {
            state.currentVariant = action.payload;
            const item = state.savedReports.find(
                ({ type }) => type === action.payload
            );
            state.activeIndex = item?.id ?? null;
        },
        formPeriod: (
            state,
            action: PayloadAction<PeriodConfiguration | null>
        ) => {
            state.filterPeriodCurrent = action.payload;
        },
        editReport: (state, action: PayloadAction<IReportState>) => {
            state.savedReports = state.savedReports.map((el) => {
                if (el.id === state.activeIndex) {
                    return { ...el, ...action.payload };
                }
                return el;
            });
            state.filterPeriodCurrent = null;
        },
        addReport: (state, action: PayloadAction<IReportState>) => {
            const id = new Date().getTime();
            state.savedReports = [
                ...state.savedReports,
                { ...action.payload, id },
            ];
            state.filterPeriodCurrent = null;
            if (state.activeIndex === null) {
                state.activeIndex = id;
            }
        },
        removeReport: (state, action: PayloadAction<number>) => {
            state.savedReports = state.savedReports.filter(
                (report) => report.id !== action.payload
            );
        },
        changeActiveIndex: (state, action: PayloadAction<number | null>) => {
            state.activeIndex = action.payload;
            state.pointedRows = [];
        },
        togglePointedRows: (state, action: PayloadAction<number>) => {
            state.pointedRows = state.pointedRows.includes(action.payload)
                ? state.pointedRows.filter((row) => row !== action.payload)
                : [...state.pointedRows, action.payload];
        },
        setFontSize: (state, action: PayloadAction<string>) => {
            state.fontSize = action.payload;
            localStorage.setItem("fontSize", state.fontSize);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReports.pending, (state) => {
            state.status = "pending";
        });
        builder.addCase(getReports.fulfilled, (state, action) => {
            state.savedReports = action.payload;
            if (action.payload.length) {
                state.activeIndex = action.payload[0].id;
            }
            state.status = "success";
        });
        builder.addCase(getReports.rejected, (state) => {
            state.status = "rejected";
        });
    },
});

export const { actions, reducer } = excelSlice;
