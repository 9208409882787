import React, { FC, useMemo } from "react";
import { Col, Input, Row, Select } from "antd";
import { IFilter } from "src/shared/types/filters";
import styles from "./styles.module.css";
import FiltersDatePicker from "../FiltersDatePicker";
import classNames from "classnames";

const { Option } = Select;

type Props = {
    filters: IFilter[];
    itemsInRow?: number;
    value: {
        [key: string]: string | null;
    };
    onChangeFilters: (key: string, value: string | null) => void;
};

const FiltersGrid: FC<Props> = (props) => {
    const { filters, itemsInRow, value, onChangeFilters } = props;

    const columnSpan = useMemo(() => {
        return 24 / (itemsInRow ?? filters.length);
    }, [filters.length, itemsInRow]);

    return (
        <Row className={styles["filter-grid"]} gutter={10}>
            {filters.map((filter) => (
                <Col span={columnSpan} key={filter.name}>
                    {filter.type === "Date" ? (
                        <FiltersDatePicker
                            placeholder={filter.placeholder}
                            value={value[filter.name]}
                            onChangeFilters={(val: string | null) =>
                                onChangeFilters(filter.name, val)
                            }
                        />
                    ) : filter.type === "Input" ? (
                        <Input
                            placeholder={filter.placeholder ?? "Введите"}
                            type="text"
                            style={{ minHeight: 52 }}
                            value={value[filter.name] ?? ""}
                            allowClear
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => onChangeFilters(filter.name, e.target.value)}
                        />
                    ) : (
                        <Select
                            className={classNames(
                                styles["filter-select"],
                                "editable-select"
                            )}
                            notFoundContent="Ничего нет. И куда подевалось?"
                            placeholder={filter.placeholder ?? "Выберите"}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option?.children ? `${option.children}` : "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            onClear={() => {
                                onChangeFilters(filter.name, null);
                            }}
                            onChange={(val: string) =>
                                onChangeFilters(filter.name, val)
                            }
                            value={value[filter.name] ?? undefined}
                        >
                            {filter.options?.length &&
                                filter.options.map((option) => (
                                    <Option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.key}
                                    </Option>
                                ))}
                        </Select>
                    )}
                </Col>
            ))}
        </Row>
    );
};

export default FiltersGrid;
