import { useMemo } from "react";
import "./styles.scss";
import LogoSvg from "../Logo/logo";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { Popover, Tooltip } from "antd";
import { logout } from "src/services/login/loginSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

// window.location.pathname;
const regExp = /^\/(login)?$/g;

const Header = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const hidden = useMemo<boolean>(
        () => regExp.test(location.pathname),
        [location.pathname]
    );

    const onExit = () => {
        dispatch(logout());
    };

    if (hidden) return null;
    return (
        <div className="header-container">
            <Popover
                content={BurgerMenu}
                trigger="click"
                style={{ padding: 0 }}
            >
                <div className="logo-container">
                    <MenuOutlined />
                    <LogoSvg  />
                </div>
            </Popover>

            <div>
                {localStorage.getItem("username")}
                <Tooltip title="Выйти" placement="left">
                    <a className="exit-icon" onClick={onExit}>
                        <LogoutOutlined />
                    </a>
                </Tooltip>
            </div>
        </div>
    );
};

const links: { link?: string; title: string; free?: boolean }[] = [
    {
        link: 'point-events-lineman',
        title: 'Создание событий'
    },
    {
        link: "supply-point-cell-mapping",
        title: "Ячейки по ОЛС",
    },
    {
        link: "mapping-cells",
        title: "Маппинг ячеек",
    },
    { title: "Позиции (агрегаты)", link: "positions" },
    // { title: "Маппинг Установка-Объект" },
    { title: "Типы событий", link: "types-creating" },
    { title: "Маппинг Пользователь-Объект", link: "mapping-object-users" },
    { title: "Одобрение событий", link: "point-events-acceptor", free: true },
    { title: "ИС Энергия", link: "is-energy", free: true },
    { title: "Административная панель", link: "admin-side" },
];

export const BurgerMenu = () => {
    const navigate = useNavigate();
    const { currentUser, currentUserStatus: status } = useAppSelector(
        (state) => state.Login
    );
    const actualLinks = links.filter((el) => el.free || currentUser?.IsMaster);
    if (status !== "fulfilled") return null;
    return (
        <div className="burger-menu-container">
            <ul>
                {actualLinks.map((link, id) => (
                    <li
                        onClick={() => {
                            if (link.link) navigate("/" + link.link);
                        }}
                        key={id}
                    >
                        {link.title}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Header;
