import { FC } from "react";
import Accordion from "../../Accordion";
import FiltersGrid from "../FiltersGrid";
import { IFilter } from "src/shared/types/filters";
import classNames from "classnames";

type Props = {
    title: string;
    filters: IFilter[];
    itemsInRow?: number;
    className?: string;
    value: {
        [key: string]: string | null;
    };
    onChangeFilters: (key: string, value: string | null) => void;
};

const Filters: FC<Props> = (props) => {
    const { title, className, ...restProps } = props;

    return (
        <Accordion
            title={title}
            className={classNames(className)}
        >
            <FiltersGrid {...restProps} />
        </Accordion>
    );
};

export default Filters;
