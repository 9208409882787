import { FC, useCallback, useEffect, useState } from "react";
import { AddButton } from "../../../components/UI";
import {
    Checkbox,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Select,
} from "antd";
import { ModalHeader } from "./UI";
import { EditableColumnTypes } from "src/entities/EditableTable/Types";
import classNames from "classnames";
import styles from "./styles.module.scss";

const { Option } = Select;
type Props = {
    title?: string;
    description?: string;
    showed: boolean;
    closeShowed: () => void;
    onAccept: (data: any) => Promise<void>;
    fields: EditableColumnTypes;
};

const AddItemModal: FC<Props> = (props) => {
    const { title, description, onAccept, showed, closeShowed, fields } = props;

    function onCreate() {
        return form
            .validateFields()
            .then(onAccept)
            .then(onClosed)
            .catch((_) => {
                message.error("Проверьте корректность заполненных полей");
            });
    }

    const onClosed = useCallback(() => {
        closeShowed();
        form.resetFields();
    }, [closeShowed]);

    useEffect(() => {
        if (!showed) {
            form.resetFields();
        }
    }, [showed]);

    const [form] = Form.useForm();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    return (
        <>
            <Modal
                open={isConfirmationOpen}
                title="Подтвердить закрытие"
                centered
                width={700}
                onOk={() => {
                    setIsConfirmationOpen(false);
                    onClosed();
                }}
                onCancel={() => setIsConfirmationOpen(false)}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <OkBtn />
                        <CancelBtn />
                    </>
                )}
            ></Modal>
            <div
                className={classNames(styles["modal-background"], {
                    [styles.none]: !showed,
                })}
                onClick={() => setIsConfirmationOpen(true)}
            >
                <div
                    className={styles["modal-body"]}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={styles["scroll-container"]}>
                        <ModalHeader
                            title={title}
                            description={description}
                            closeShowed={onClosed}
                        />
                        <Form
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 48 }}
                            form={form}
                        >
                            {fields.map((field) => (
                                <Form.Item
                                    key={field.dataIndex}
                                    name={field.dataIndex}
                                    label={field.title as string}
                                    valuePropName={
                                        field?.type === "checkbox"
                                            ? "checked"
                                            : "value"
                                    }
                                    required={field.required}
                                    rules={
                                        field.required
                                            ? [
                                                  {
                                                      required: true,
                                                      message:
                                                          "Обязательное поле",
                                                  },
                                              ]
                                            : []
                                    }
                                >
                                    {field.options ? (
                                        <Select
                                            optionFilterProp="key"
                                            notFoundContent="Не найдено"
                                            showSearch
                                            mode={
                                                field.multiple
                                                    ? "multiple"
                                                    : undefined
                                            }
                                            allowClear
                                            placeholder={field.title as string}
                                        >
                                            {field.options?.map((option) => (
                                                <Option
                                                    value={option.value}
                                                    key={`${option.key}${option.value}`}
                                                >
                                                    {option.key}
                                                </Option>
                                            ))}
                                        </Select>
                                    ) : field?.type === "number" ? (
                                        <InputNumber
                                            placeholder={field.title as string}
                                        />
                                    ) : field?.type === "checkbox" ? (
                                        <Checkbox />
                                    ) : (
                                        <Input
                                            placeholder={field.title as string}
                                        />
                                    )}
                                    {/* <FieldFabric field={field} /> */}
                                </Form.Item>
                            ))}
                        </Form>
                    </div>
                    <AddButton
                        className={styles["modal__button"]}
                        onClick={onCreate}
                    >
                        Создать
                    </AddButton>
                </div>
            </div>
        </>
    );
};

export default AddItemModal;
