import { toast } from "react-toastify";

export type ToNull<T extends object> = { [U in keyof T]: T[U] | null };
export const callError = (text: string) =>
    toast.error(text, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    });

export const callSuccess = (text: string) => {
    toast.success(text, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
    });
};

export const parseObjectToString = (obj: {
    [key: string]: string | number | null;
}): string => {
    return Object.entries(obj).reduce(
        (acc: string, cur: [string, string | number | null]) => {
            const [key, val] = cur;
            return `${acc}&${key}=${val}`;
        },
        ""
    );
};
