import {
    IModifier,
    IPointEvent,
} from "src/services/pointEvents/pointEventsSlice";
import { Positions, Series } from "./types";
import { AxisOptions } from "react-charts";

const minutesDictionary = {
    "15": 1,
    "30": 2,
    "45": 3,
    "59": 4,
};

function parseTime(date: string) {
    const [hours, minutes] = date === '' ? ['', ''] : date.split("T")[1].split(":");
    return (
        +hours * 4 +
        (minutesDictionary[minutes as keyof typeof minutesDictionary] || 0)
    );
}

export const fillChartWithValues = (
    chart: Positions[],
    position: IModifier
) => {
    const beginHour = parseTime(position.BeginDate);
    const endHour = parseTime(position.EndDate);
    for (let i = beginHour; i <= endHour; i++) {
        chart.push({ value: position?.Value, date: i });
    }
    const difference = beginHour >= 4 ? 4 : beginHour;
    for (let i = 0; i < difference; i++) {
        if (!chart.find((el) => el.date === beginHour - difference + i)) {
            chart.unshift({
                value: Math.round((position.Value / difference) * i),
                date: beginHour - difference + i,
            });
        }
    }
};

export const fillChartWithZeros = (chart: Positions[]) => {
    for (let i = 1; i <= 96; i++) {
        if (!chart.find((el) => el.date === i)) {
            chart.push({ value: 0, date: i });
        }
    }
    chart.sort((a, b) => a.date - b.date);
};

export const drawReserveValue = (point: IPointEvent): Series[] => {
    const data: Series[] = [];
    const charts: Positions[][] = [];

    point.ModifierData.forEach((modifier, index) => {
        charts.push([]);
        fillChartWithValues(charts[index], modifier);
        fillChartWithZeros(charts[index]);
        data.push({
            label: point.SupplyPointName ?? `График ${index + 1}`,
            data: charts[index],
        });
    });
    return data;
};

export const primaryAxis: AxisOptions<Positions> = {
    getValue: (datum) => datum.date,
    show: false,
    showDatumElements: false,
};

export const secondaryAxes: AxisOptions<Positions>[] = [
    {
        getValue: (datum) => datum.value,
        show: false,
        showDatumElements: false,
    },
];
