import { useEffect, useState } from "react";
import { getPositionsByObjectId } from "src/services/pointEvents/api";
import { IEventPosition } from "src/services/pointEvents/pointEventsSlice";

export const usePositions = (startObjectId?: number) => {
    const [availablePositions, setAvailablePositions] = useState<
        IEventPosition[]
    >([]);
    const [isLoading, setIsLoading] = useState(true);

    async function fetchAvailablePositions(objectId?: number) {
        
        const data =  await getPositionsByObjectId({ supplyPointId: objectId });
        setAvailablePositions(data);
        setIsLoading(false);
        return data;
    }

    useEffect(() => {
        if (startObjectId) {
            fetchAvailablePositions(startObjectId);
        }
    }, [])

    return {
        availablePositions,
        isLoading,
        setAvailablePositions: fetchAvailablePositions,
    };
};
