import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { ICurrentUser } from "src/services/login/loginSlice";

type Props = {
    user: ICurrentUser | null;
    forMaster: boolean;
    name: string;
};

const ProtectedRoute: FC<PropsWithChildren<Props>> = (props) => {
    const { children, user, forMaster, name } = props;
    const blockNavigate = forMaster && !user?.IsMaster;

    if (!blockNavigate) {
        document.title = name;
    }

    if (blockNavigate) {
        return <Navigate to="/is-energy" replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
