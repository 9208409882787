/* eslint-disable no-restricted-globals */
import axios from "axios";
import { callError } from "src/shared/helpers";

export const urlApi =
    location.hostname === "localhost"
        ? "https://dev.enrsoft.ru"
        : // для публикации в закрытом контуре lukoil
          "https://" + location.hostname + "/api";

const errorHandler = (error: unknown) => {
    console.log(error);
    callError("Возникла ошибка при выполнении запроса");

    return Promise.reject(error);
};

const isNotLocalhost = location.hostname !== "localhost";

export const BaseInstanse = axios.create({
    withCredentials: isNotLocalhost,
    baseURL: `${urlApi}`,
    headers: { "Content-Type": "multipart/form-data" },
});

export const RestInstanse = axios.create({
    withCredentials: isNotLocalhost,
    baseURL: `${urlApi}/rest`,
    // headers: { "Content-Type": "multipart/form-data","Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS", }
});

RestInstanse.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error?.request?.status);
        if ([302, 500].includes(error?.request?.status))
            return errorHandler(error);
    }
);
