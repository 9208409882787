import { FC, } from "react";
import { ICellMapping } from "src/entities/MappingPositionsCell/Types/mapPositions";
import {
    IEventPosition,
    IPeriodModifier,
} from "src/services/pointEvents/pointEventsSlice";
import styles from "./styles.module.css";
import PeriodSelect from "./PeriodSelect";
import { OnePositionSelect } from "src/entities/PointEvents/components";
import { usePointEventsContext } from "src/entities/PointEvents/context/PointEventsContext";

type Props = {
    period: IPeriodModifier;
    id: number;
    updatePeriod: (id: number, period: IPeriodModifier) => void;
    cellMappings: ICellMapping[][];
    positionId?: number | null;
    updatePosition?: (position: IEventPosition) => void;
};

const mappingSelectCountArray = [...Array(2).keys()];

const PeriodHeader: FC<Props> = (props) => {
    const { period, id, updatePeriod, cellMappings, positionId, updatePosition } = props;

    function updateReservePosition(val: number) {
        const newPosition = availablePositions.find((pos) => pos.Id === val);
        if (!newPosition) return;
        updatePosition?.(newPosition)
    }

    function updateMappingValue(val: number, index: number) {
        const currentMap = cellMappings[index].find((map) => map.Id === val);

        if (!currentMap) return;
        const currentValue = [...period.Value];
        if (isTransition) {
            const currentIds = period.CellMappingId as number[];
            currentIds.splice(index, 1, val);
            const currentValues = [...period.Value];
            currentValues.splice(index, 1, currentMap.InstCapacity);
            updatePeriod(id, {
                ...period,
                Value: currentValues,
                CellMappingId: currentIds,
            });
        } else {
            currentValue.splice(0, 1, currentMap.InstCapacity);
            updatePeriod(id, {
                ...period,
                Value: currentValue,
                CellMappingId: val,
            });
        }
    }

    const isTransition = Array.isArray(period.CellMappingId);
    const { availablePositions } = usePointEventsContext();

    return (
        <>
            <div className={styles.period}>Период {id + 1}</div>
            <div
                style={{ display: positionId === undefined ? "none" : "block" }}
            >
                <OnePositionSelect
                    availablePositions={availablePositions}
                    value={positionId ?? null}
                    updateCurrentPosition={updateReservePosition}
                />
            </div>
            <div className={styles["period-mappings"]}>
                {(isTransition ? mappingSelectCountArray : [0]).map((item) => (
                    <PeriodSelect
                        key={item}
                        setPeriod={updateMappingValue}
                        period={period}
                        index={item}
                        options={cellMappings[item]}
                    />
                ))}
            </div>
        </>
    );
};

export default PeriodHeader;
