import React, { FC, useMemo } from "react";
import { IPointEvent } from "src/services/pointEvents/pointEventsSlice";
import { Toggler } from "src/shared/components";
import styles from "./styles.module.css";

type Props = {
    role: "lineman" | "acceptor";
    point: IPointEvent;
    onAcceptEvent: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
};

const EventStatus: FC<Props> = React.memo((props) => {
    const { role, point, onAcceptEvent } = props;

    const pointStatusColor = useMemo(() => {
        switch (point.IsAccepted) {
            case true:
                return "#32B232";
            case false:
                return "#ED1A3B";
            default:
                return "#efd44f";
        }
    }, [point]);

    if (role === "acceptor")
        return (
            <Toggler
                onChange={(e) => onAcceptEvent(e, point.Id as number)}
                checked={point?.IsAccepted ?? false}
            />
        );
    return (
        <div
            className={styles["event-status"]}
            style={{ backgroundColor: pointStatusColor }}
        />
    );
});

export default EventStatus;
