import "./styles/App.scss";
import "./styles/normalize.css";
import "./styles/fonts.css";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import PointEventsPage from "./pages/PointEventsPage/PointEventsPage";
import { PointcellMappingPage } from "./pages/PointCellMapping/PointCellMappingPage";
import Header from "./components/Header/header";
import { lazy } from "react";
import { useGetCurrentUser } from "./hooks/currentUserHook";
import { BigLoader } from "./components/UI";
import ProtectedRoute from "./shared/components/ProtectedRoute";
import { ICurrentUser } from "./services/login/loginSlice";

const TablePage = lazy(() => import("./ExcelTable"));
const CmsPage = lazy(() => import("./pages/CMSPage"));
const CellPage = lazy(() => import("./pages/CellPage"));
const MappingCellPage = lazy(() => import("./pages/MappingCellPage"));
const TypesPage = lazy(() => import("./pages/TypesPage"));
const MappingUserObjectPage = lazy(() => import("./pages/MappingUserObject"));

const routes = [
    { path: "/", Component: <LoginPage />, common: true, name: "Авторизация" },
    {
        path: "/login",
        Component: <LoginPage />,
        common: true,
        name: "Авторизация",
    },
    {
        path: "/point-events-acceptor",
        Component: <PointEventsPage />,
        common: true,
        name: "Одобрение событий",
    },
    { path: "/positions", Component: <CellPage />, name: "Позиции (агрегаты)" },
    {
        path: "/point-events-lineman",
        Component: <PointEventsPage />,
        name: "События",
    },
    {
        path: "/supply-point-cell-mapping",
        Component: <PointcellMappingPage />,
        name: "Ячейки по ОЛС",
    },
    {
        path: "/mapping-cells",
        Component: <MappingCellPage />,
        name: "Маппинг ячеек",
    },
    {
        path: "/mapping-object-users",
        Component: <MappingUserObjectPage />,
        name: "Маппинг доступов к объектам",
    },
    {
        path: "/admin-side",
        Component: <CmsPage />,
        name: "Адиминистративная панель",
    },
    {
        path: "/types-creating",
        Component: <TypesPage />,
        name: "Типы событий",
    },
    {
        path: "is-energy",
        Component: <TablePage />,
        common: true,
        name: "ИС-Энергия",
    },
];

function generateRoutes(user: ICurrentUser | null) {
    if (user === null) return { values: routes.slice(0, 2), path: "/" };
    return { values: routes, path: "/point-events-lineman" };
}

function App() {
    const { currentUser, status } = useGetCurrentUser();

    const isLoading: boolean = status === "pending";

    const { values: actualRoutes, path: defaultPath } =
        generateRoutes(currentUser);

    return (
        <div className="event-page-container">
            <Header />
            {isLoading ? (
                <BigLoader />
            ) : (
                <Routes>
                    {actualRoutes.map(({ common, Component, path, name }) => (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <ProtectedRoute
                                    forMaster={!common}
                                    user={currentUser}
                                    name={name}
                                >
                                    {Component}
                                </ProtectedRoute>
                            }
                        />
                    ))}
                    {/* <Route path="/" element={<LoginPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/point-events-acceptor"
                        element={<PointEventsPage />}
                    />
                    <Route path="positions" element={<CellPage />} />
                    <Route
                        path="/point-events-lineman"
                        element={<PointEventsPage />}
                    />
                    <Route
                        path="/supply-point-cell-mapping"
                        element={<PointcellMappingPage />}
                    />
                    <Route
                        path="/mapping-cells"
                        element={<MappingCellPage />}
                    />
                    <Route
                        path="/mapping-object-users"
                        element={<MappingUserObjectPage />}
                    />
                    <Route path="/admin-side" element={<CmsPage />} />
                    <Route path="/types-creating" element={<TypesPage />} />
                    <Route
                        path="is-energy"
                        element={<TablePage tables={propsiki} />}
                    /> */}
                    <Route path="*" element={<Navigate to={defaultPath} />} />
                </Routes>
            )}
        </div>
    );
}

export default App;
