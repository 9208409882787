import { useState } from "react";

export const useUpdateArray = <T extends { key: number }>(
  defaultData?: T[]
): [
  T[],
  {
    update: (key: number, name: keyof T, value: T[keyof T]) => void;
    toggle: (elem: T) => void;
  }
] => {
  function updateElem(key: number, name: keyof T, value: T[keyof T]) {
    setData((prev) =>
      prev.map((el) => (el.key === key ? { ...el, [name]: value } : el))
    );
  }

  // function deleteElem(key: number) {
  //   setData((prev) => prev.filter((el) => el.key !== key));
  // }

  function toggleElem(elem: T) {
    const hasElem = data.find((el) => el.key === elem.key);
    setData((prev) => {
      if (hasElem) return prev.filter((el) => el.key !== elem.key);
      return [...prev, elem];
    });
  }

  const [data, setData] = useState<T[]>(defaultData ?? []);

  return [data, { toggle: toggleElem, update: updateElem }];
};
