import React, { FC } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

const RedButton: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (
    props
) => {
    const { children, className, ...other } = props;

    return (
        <button
            {...other}
            className={classNames(styles["red-button"], className)}
        >
            {children}
        </button>
    );
};

export default RedButton;
