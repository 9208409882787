import { Select } from "antd";
import { FC, useMemo } from "react";
import { ICellMapping } from "src/entities/MappingPositionsCell/Types/mapPositions";
import { IPeriodModifier } from "src/services/pointEvents/pointEventsSlice";

const { Option } = Select;

type Props = {
    setPeriod(val: number, index: number): void;
    index: number;
    period: IPeriodModifier;
    options: ICellMapping[];
};

const PeriodSelect: FC<Props> = (props) => {
    const { setPeriod, index, period, options } = props;

    const filteredOptions = useMemo(() => {
        if (options === undefined) return [];
        if (!Array.isArray(period.CellMappingId)) return options;
        const choosedMaps = [...period.CellMappingId];
        choosedMaps.splice(index, 1);
        return options.filter((el) => !choosedMaps.includes(el.Id));
    }, [options, index, period]);

    return (
        <Select
            notFoundContent="Не найдено"
            placeholder="Ячейки"
            className="editable-select"
            onChange={(val: number) => setPeriod(val, index)}
            value={
                (Array.isArray(period.CellMappingId)
                    ? period.CellMappingId[index]
                    : period.CellMappingId) || null
            }
        >
            {filteredOptions.map((el) => (
                <Option value={el.Id} key={el.Id}>
                    {el.Description ??
                        `От ${el.SourceSupplyPointName} до ${el.TargetSupplyPointName}`}
                </Option>
            ))}
        </Select>
    );
};

export default PeriodSelect;
