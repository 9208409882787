import { Button, Space } from "antd";
import React, { FC, useMemo, useState } from "react";
import { defaultConfig } from "src/entities/EditableTable/Constants/actionCell";
import { IActionItem } from "src/entities/EditableTable/Types/actionCell";
import { useEditableCellContext } from "src/entities/EditableTable/context/EditableCellContext";

type Props = {
  item: IActionItem;
  disabled: boolean;
  updateDisable: (value: boolean) => void;
};

const EditableActionButton: FC<Props> = (props) => {
  const { item, disabled, updateDisable } = props;

  const { changeEditing, activeItem, record, refetch } =
    useEditableCellContext();

  const isEditing = useMemo<boolean>(
    () => activeItem !== undefined,
    [activeItem]
  );

  function onSaveAction() {
    updateDisable(true);
    item.action(activeItem ?? record).finally(() => {
      updateDisable(false);
      changeEditing();
      refetch && refetch();
    });
  }

  function onAction() {
    if (item.type !== "edit") {
      updateDisable(true);
      item.action(activeItem ?? record).finally(() => {
        updateDisable(false);
        refetch && refetch();
      });
    } else {
      changeEditing();
    }
  }

  const defaultElement = defaultConfig[item.type];

  // если в режиме редактирования, и я не кнопка редактирования, то ничего не отрисовывать
  if (isEditing && item.type !== "edit") return null;
  // если в режиме редактирования, и я, логично, кнопка редактирования, то отрисовать кнопки сохранения и отмены
  if (isEditing)
    return (
      <Space size={8} align="center">
        <Button
          type={defaultConfig.save.buttonType}
          icon={defaultConfig.save.icon}
          danger={defaultConfig.save.danger}
          onClick={onSaveAction}
          disabled={disabled}
        />
        <Button
          type={defaultConfig.cancel.buttonType}
          icon={defaultConfig.cancel.icon}
          danger={defaultConfig.cancel.danger}
          onClick={changeEditing}
          disabled={disabled}
        />
      </Space>
    );
  // в противном случае просто отрисоваться по конфигу
  return (
    <Button
      type={item.buttonType ?? defaultElement.buttonType}
      icon={item.icon ?? defaultElement.icon}
      danger={item.danger ?? defaultElement.danger}
      onClick={onAction}
      disabled={disabled}
    />
  );
};

export default EditableActionButton;
