import { useEffect, useState } from "react";

export interface FetchFunction<P, T> {
    (params?: P): Promise<T>;
}

interface UseFetchResult<T> {
    data: T | null | undefined;
    isLoading: boolean;
    error: Error | null;
    trigger: () => Promise<void>;
}

export const useFetch = <T, P>(
    fetchFunction: FetchFunction<P, T>,
    params?: P,
    dependence?: number | string
): UseFetchResult<T> => {
    const [data, setData] = useState<T | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const stringParams = params ? new URLSearchParams(params).toString() : "";
    async function call() {
        try {
            setIsLoading(true);
            const result = await fetchFunction(params);
            setData(result);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        call();
    }, [fetchFunction, stringParams, dependence]);

    return { data, isLoading, error, trigger: call };
};
