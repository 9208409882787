import { DeleteOutlined } from "@ant-design/icons";
import { Table, Typography } from "antd";
import { AnyObject } from "antd/lib/_util/type";
import React, { FC, useMemo } from "react";
import { Chart } from "react-charts";
import Moment from "react-moment";
import { ColumnTypes } from "src/entities/EditableTable/Types";
import {
    EventStatus,
    TableRow,
    TableTh,
} from "src/entities/PointEvents/components";
import { primaryAxis, secondaryAxes } from "src/pages/PointEventsPage/helpers";
import { Series } from "src/pages/PointEventsPage/types";
import {
    IModifier,
    IPointEvent,
} from "src/services/pointEvents/pointEventsSlice";

type Props = {
    allPointEvents: IPointEvent[];
    role: "acceptor" | "lineman";
    callAcceptEvent: (val: IPointEvent) => void;
    chartsData: Record<string, Series[]>;
    onDeleteEvent: (
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        id: number
    ) => void;
    onEditEvent: (
        e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,
        curr: IPointEvent
    ) => void;
};

function parsePositions(item: IModifier[]): string {
    const result = item.reduce<string[]>((acc, cur) => {
        if (!cur.Position) return acc;
        return [...acc, cur.Position];
    }, []);

    return result.join(", ");
}
const paginationOptions = {
    pageSize: 20,
    defaultPageSize: 20,
    hideOnSinglePage: true,
    showSizeChanger: false,
};

const components = {
    header: {
        cell: TableTh,
    },
    body: {
        row: TableRow,
    },
};

const EventTable: FC<Props> = (props) => {
    const {
        role,
        allPointEvents,
        callAcceptEvent,
        chartsData,
        onDeleteEvent,
        onEditEvent,
    } = props;

    const allPointEventsWithKey = useMemo(() => {
        return allPointEvents.map((el) => ({ ...el, key: el.Id }));
    }, [allPointEvents]);

    const columns = useMemo<ColumnTypes>(() => {
        return [
            {
                title: "Статус",
                dataIndex: "IsAccepted",
                align: "center",
                render: (_: boolean, record: AnyObject) => (
                    <EventStatus
                        role={role}
                        point={record as IPointEvent}
                        onAcceptEvent={() =>
                            callAcceptEvent(record as IPointEvent)
                        }
                    />
                ),
            },
            {
                title: "Дата начала",
                align: "center",
                dataIndex: "BeginDate",
                render: (beginDate: string) => (
                    <Moment format="D MMM YY" locale={"ru"}>
                        {beginDate}
                    </Moment>
                ),
            },
            {
                title: "Тип",
                align: "center",
                dataIndex: "TypeLocalName",
            },
            {
                title: "Объект",
                align: "center",
                dataIndex: "SupplyPointName",
            },
            {
                title: "Точки",
                align: "center",
                dataIndex: "ModifierData",
                render: (modifiers: IModifier[]) => parsePositions(modifiers),
            },
            {
                title: "График",
                dataIndex: "Id",
                className: "chart-container",
                align: "center",
                render: (id: number, record: AnyObject) => (
                    <div className="chart-container">
                        <Chart
                            options={{
                                data: chartsData?.[id.toString()],
                                primaryAxis,
                                secondaryAxes,
                            }}
                            style={{ top: "0px", height: 40 }}
                        />
                    </div>
                ),
            },
            {
                title: "Действия",
                align: "center",
                render: (_: any, record: AnyObject) =>
                    role === "lineman" &&
                    !record.IsAccepted && (
                        <div className="table-actions">
                            <DeleteOutlined
                                onClick={(e) =>
                                    onDeleteEvent(e, record?.Id as number)
                                }
                            />
                        </div>
                    ),
            },
        ];
    }, [callAcceptEvent, allPointEventsWithKey, onDeleteEvent]);

    if (!allPointEventsWithKey.length)
        return <Typography.Title level={1}>Нет событий</Typography.Title>;
    return (
        <Table
            className="point-events-table"
            onRow={(record) => {
                return {
                    onClick: (event) => {
                        onEditEvent(event, record as IPointEvent);
                        // if ([undefined, null].includes(record.IsAccepted)) {
                        //     onEditEvent(event, record as IPointEvent);
                        // }
                    }, // click row
                };
            }}
            components={components}
            dataSource={allPointEventsWithKey}
            pagination={paginationOptions}
            columns={columns}
        />
    );
};

export default EventTable;
