import { Select } from "antd";
import React, { FC, useEffect } from "react";
import {
    IEventObject,
    IEventPosition,
} from "src/services/pointEvents/pointEventsSlice";

const { Option } = Select;

type Props = {
    availablePositions: IEventPosition[];
    disabled?: boolean;
    value: number | null;
    updateCurrentPosition: (val: number) => void;
};

const SelectOnePosition: FC<Props> = (props) => {
    const { disabled, value, updateCurrentPosition, availablePositions } =
        props;

    useEffect(() => {
        if (availablePositions.length) {
            updateCurrentPosition(availablePositions[0].Id);
        }
    }, [availablePositions]);

    return (
        <Select
            disabled={disabled}
            notFoundContent="Не найдено"
            className="editable-select"
            placeholder="Позиция:"
            onChange={updateCurrentPosition}
            value={value ?? null}
        >
            {availablePositions.map((pos) => (
                <Option value={pos.Id} key={pos.Id}>
                    {pos.Position} ({pos.MappedSupplyPointName})
                </Option>
            ))}
        </Select>
    );
};

export default SelectOnePosition;
