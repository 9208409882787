import React, { createContext, useContext } from "react";
import { IEventType } from "src/entities/Types/types";
import { IEventPosition } from "src/services/pointEvents/pointEventsSlice";


interface IPointEventsContext {
    availableTypes: IEventType[]
    onAcceptEvent: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void
    availablePositions: IEventPosition[]
}

export const PointEventsContext = createContext<
  IPointEventsContext | undefined
>(undefined);

export const usePointEventsContext = () => {
  const context = useContext(PointEventsContext);
  if (!context) throw new Error("context error");
  return context;
};
